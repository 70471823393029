import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AlertsService } from './../../shared/services/alerts.service';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  modalRef: BsModalRef;
  alertsLoading = true;
  saveLoading = false;
  listAlerts = [];
  baseForm = this.formBuilder.group({
    ativo: true,
    tipo: ["", Validators.required],
    autenticado: false,
    mensagemPt: [null, Validators.required],
    mensagemEn: [null, Validators.required],
    sk: null,
    initialDate: ["", Validators.required],
    initialTime: ["", Validators.required],
    finalDate: ["", Validators.required],
    finalTime: ["", Validators.required]
  });
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    language: {
      lengthMenu: "Mostrar _MENU_ resultados por página",
      search: "Filtrar por:",
      zeroRecords: "Nada encontrado",
      info: "Exibindo _START_ a _END_  de _TOTAL_ resultados",
      infoFiltered: "(filtrado de total de _MAX_ resultados)",
      infoEmpty: "0 Resultados",
      loadingRecords: "Carregando...",
      processing: "Processando...",
      paginate: {
        first: "<<",
        last: ">>",
        next: ">",
        previous: "<",
      },
    },
  };
  mindt1 = new Date();
  mindt2 = new Date();

  constructor(
    private alertService: AlertsService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder
    ) { }

  ngOnInit(): void {
    this.getAlerts();
  }

  dateSelected() {
    let data: Object = this.baseForm.value;
    this.mindt2 = new Date(data["initialDate"]);
  }

  getAlerts(){
    this.alertsLoading = true;
    this.alertService.getAlerts().then((res) => {
      this.listAlerts = res['Items']
      this.alertsLoading = false;
    })
  }  

  openModal(template: TemplateRef<any>, type) {
    this.modalRef = this.modalService.show(template, { class: "modal-md" });
    this.baseForm.setValue({
      ativo: true,
      autenticado: false,
      mensagemPt: "",
      mensagemEn: "",
      tipo: "",
      sk: null,
      initialDate: new Date(),
      initialTime: new Date(),
      finalDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      finalTime: new Date()
    });
      
    if (typeof type === "object") {    
      let initialDate = type.dados?.intervaloAtivo?.de;
      let finalDate = type.dados?.intervaloAtivo?.ate;
      this.mindt1 = initialDate ? new Date(initialDate) : new Date();
      this.mindt2 = finalDate ? new Date(finalDate) : new Date(new Date().setDate(new Date().getDate() + 1));
      this.baseForm.patchValue({
        ativo: Boolean(type.ativo),
        mensagemPt: type.dados.mensagemPt,
        mensagemEn: type.dados.mensagemEn,
        sk: type.sk,
        tipo: type.dados.tipo,
        autenticado: type.autenticado,
        initialDate: initialDate ? new Date(initialDate) : new Date(),
        initialTime: initialDate ? new Date(initialDate) : new Date(),
        finalDate: finalDate ? new Date(finalDate) : new Date(new Date().setDate(new Date().getDate() + 1)),
        finalTime: finalDate? new Date(finalDate) : new Date()
      });
      
    }

    
  }

  formatData(value: any) {
    value = value.toString()
    
    return value.length > 1 ? value : '0' + value;
  }

  onSubmit(){
    let data: Object = this.baseForm.value, msg = 'Alerta atualizado com sucesso!';
    let horarioInicial = new Date(data['initialTime']), horarioFinal = new Date(data['finalTime']);
    
    data['dados'] = {
      mensagemPt: data['mensagemPt'],
      mensagemEn: data['mensagemEn'],
      tipo: data['tipo'],
      intervaloAtivo: {
       de: new Date(data["initialDate"]).getUTCFullYear() +'-'+ this.formatData(new Date(data["initialDate"]).getUTCMonth() + 1) +'-'+ this.formatData(new Date(data["initialDate"]).getUTCDate())+'T'+ this.formatData(horarioInicial.getHours())+':'+ this.formatData(horarioInicial.getMinutes())+':00',
    ate: new Date(data["finalDate"]).getUTCFullYear() +'-'+ this.formatData(new Date(data["finalDate"]).getUTCMonth() + 1) +'-'+ this.formatData(new Date(data["finalDate"]).getUTCDate())+'T'+ this.formatData(horarioFinal.getHours())+':'+ this.formatData(horarioFinal.getMinutes())+':00'
      }
    };

    if(data['sk'] === null) {
      delete data['sk'];
      msg = 'Alerta criado com sucesso!';
    }

    delete data['mensagemEn'];
    delete data['mensagemPt'];
    delete data['tipo'];
    delete data['initialDate'];
    delete data['initialTime']
    delete data['finalDate'];
    delete data['finalTime']
    this.saveLoading = true;
       
    this.alertService.putAlerts(data).then(res => {
      this.listAlerts = res['Items'];
      this.closeModal();
      this.toastr.success(msg);
      this.saveLoading = false;
    }).catch(error => {
      this.toastr.error("Desculpe, ocorreu um erro ao criar novo Link.");
      this.saveLoading = false;
    })
  }

  closeModal() {
    this.modalRef.hide();
  }

  applyCssError(field) {
    return {
      "is-invalid": this.verifyValidTouched(field),
    };
  }

  verifyValidTouched(field) {
    return !this.baseForm.get(field).valid && this.baseForm.get(field).touched;
  }

  convertData(data: string) {
   return this.formatData(new Date(data).getUTCDate())  +'-'+ this.formatData(new Date(data).getUTCMonth() + 1) +'-'+ new Date(data).getUTCFullYear() +' '+ this.formatData(new Date(data).getHours())+':'+ this.formatData(new Date(data).getMinutes());
  }
}
